<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <el-input v-model="search.hash" placeholder="请输入交易hash值"></el-input>
      <el-input v-model="search.wallet" placeholder="请输入用户钱包"></el-input>
      <el-date-picker
        v-model="search.date"
        type="date"
        placeholder="选择交易日期"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>
      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
        </el-table-column>
        <el-table-column prop="transHash" label="交易哈希" width="220">
        </el-table-column>
        <el-table-column prop="transStatus" label="交易状态" width="80">
        </el-table-column>
        <el-table-column prop="fromWallet" label="转账钱包" width="200">
        </el-table-column>
        <el-table-column prop="transTime" label="交易时间" width="180">
        </el-table-column>
        <el-table-column prop="transValue" label="交易金额" width="90">
        </el-table-column>
        <el-table-column prop="contractAddress" label="合约" width="200">
        </el-table-column>
        <el-table-column prop="tokenSymbol" label="类型"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              :disabled="scope.row.transStatus == '待匹配' ? false : true"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "链上记录",
        },
      ], // 面包屑数据

      search: {
        hash: "",
        wallet: "",
        date: "",
      },

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getChainTransactionLog,
      method: "POST",
      params: JSON.stringify({
        tranHash: "",
        wallet: "",
        transTime: "",
        date: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map((item) => this.changeItem(item));
          hideLoading();
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getChainTransactionLog,
        method: "POST",
        params: JSON.stringify({
          tranHash: this.search.hash,
          wallet: this.search.wallet,
          transTime: this.search.date,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => this.changeItem(item));
            hideLoading();
          } else if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getChainTransactionLog,
        method: "POST",
        params: JSON.stringify({
          tranHash: this.search.hash,
          wallet: this.search.wallet,
          transTime: this.search.date,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.list.map((item) => this.changeItem(item));
            hideLoading();
          } else if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row) {
      this.$prompt("请输入备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.+$/,
        inputErrorMessage: "请输入备注",
      })
        .then(({ value }) => {
          showLoading();
          const opt = {
            url: reqUrl.cancelChainTransLog,
            method: "POST",
            params: JSON.stringify({
              tranHash: row.transHash,
              remark: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
                hideLoading();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    reset() {
      this.search.hash = "";
      this.search.wallet = "";
      this.search.date = "";
      this.refresh();
    },

    changeItem(item) {
      if (item.transStatus == 1) {
        item.transStatus = "待匹配";
      } else if (item.transStatus == 2) {
        item.transStatus = "匹配成功";
      } else if (item.transStatus == 3) {
        item.transStatus = "匹配失败";
      }

      item.transTime = format_time_date(item.transTime);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 300px;
    margin-right: 10px;
  }
}
</style>
